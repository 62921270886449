import React from "react"

import Layout from "../components/Layout/Layout";
import SEO from "../components/seo"

const NotFoundPage = () => (
  <Layout>
    <SEO title="404: Not found" />
    <div style={{ width: '100%', padding: '60px 30px', display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'column'}}>
      <h2 className="title is-2">Sitio No Encontrado</h2>
      <h3 className="subtitle is-3">La ruta ingresada no existe.</h3>
    </div>
  </Layout>
)

export default NotFoundPage
